<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="items"
  :pageCount="0"
  :currentPage="0"
  :querySearch="getDocuments"
  :isLoading="isLoading"
  :actions="actions"
  isHiddenTableSearch
  isHiddenTableCount
  isHiddenPageCount
)
  BackOfficeCoursePricesOnlineAdd(slot="AddBlock" :getDocuments="getDocuments")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    BackOfficeCoursePricesOnlineAdd: () => import('./BackOfficeCoursePricesOnlineAdd.vue')
  },
  data () {
    return {
      headers: [
        { value: 'date_start',
          text: this.$i18n.t('dateEffective'),
          sortable: false
        },
        { value: 'date_end',
          text: this.$i18n.t('dateTermination'),
          sortable: false
        },
        { value: 'course',
          text: this.$i18n.t('course'),
          sortable: false
        },
        { value: 'price',
          text: this.$i18n.t('price'),
          sortable: false
        },
        { value: 'type_of_form',
          text: this.$i18n.t('priceForm'),
          sortable: false
        },
        { value: 'event',
          text: this.$i18n.t('actions'),
          class: 'mw-0',
          sortable: false
        }
      ],
      actions: [
        { id: 1, tooltip: 'tooltip.go', action: (item) => this.goToDocument(item), color: 'blue', name: 'mdi-information-outline', isView: true }
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.coursePriceOnline
    })
  },
  methods: {
    ...mapActions(['getCoursesPriceOnline']),
    async getDocuments (filter) {
      this.isLoading = true
      await this.getCoursesPriceOnline(filter)
      this.isLoading = false
    },
    goToDocument (item) {
      this.$router.push(`/back-office-price/online/eti/price-course/${item.id}`)
    }
  }
}
</script>
